import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './error404/error404.component';
import { AtpAnonimusGuard } from './atp-core/guards/atp-anonimus.guard';
import { AtpAuthGuard } from './atp-core/guards/atp-auth.guard';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: '/lots',
        pathMatch: 'full',
        // canActivate: [
        //   AtpAuthGuard
        // ]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [
          AtpAuthGuard
        ],
      },
      {
        path: 'lots',
        loadChildren: () => import('./lots/lots.module').then(m => m.LotsModule),
        // canActivate: [
        //   AtpAuthGuard
        // ],
      },
      {
        path: 'lottemplates',
        loadChildren: () => import('./lot-templates/lot-templates.module').then(m => m.LotTemplatesModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'handbooks',
        loadChildren: () => import('./handbooks/handbooks.module').then(m => m.HandbooksModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'settings2',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'subscriptionsectionproducts',
        loadChildren: () => import('./subscription-section-products/subscription-section-products.module').then(m => m.SubscriptionSectionProductsModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'history',
        loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'instruction',
        loadChildren: () => import('./instruction/instruction.module').then(m => m.InstructionModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [
          AtpAuthGuard
        ]
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [
          AtpAnonimusGuard
        ]
      }
    ]
  },
  {
    path: 'design-system',
    loadChildren: () => import('./design-system/design-system.module').then(m => m.DesignSystemModule),
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', scrollOffset: [0, 64] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
