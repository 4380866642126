import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventArgs, IAtpSelectItem} from '../../atp-core';
import {FormGroup, FormControl} from '@angular/forms';
import {AtpFiltersPanelItem, AtpFiltersPanelSelect} from './models';
import {from, Observable, Subscription} from 'rxjs';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {AtpDateTimeService} from '../../components/atp-range-date-time-picker/atp-date-time.service';
import {log} from "util";
import {debounceTime} from 'rxjs/operators';
import {AtpHttpErrorHandler, AtpHttpErrorsService} from '../../services/atp-http-errors.service';
import {AtpHttpService, IAtpUser} from '../../services/atp-http.service';

interface IAtpFiltersPanelChip {
  name: string;
  display: string;
}

@Component({
  selector: 'atp-filters-panel',
  templateUrl: './atp-filters-panel.component.html',
  host: {
    class: 'atp-filters-panel'
  }
})
export class AtpFiltersPanelComponent implements OnInit {

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _renderer: Renderer2,
    private _dateTimeService: AtpDateTimeService,
    protected api: AtpHttpService<IAtpUser>,
    protected httpErrors: AtpHttpErrorsService
    ) {
  }

  private _prefix: string;
  @Input()
  get prefix(): string {
    return this._prefix;
  }

  set prefix(value: string) {
    if (this._prefix) {
      this._renderer.removeClass(this._elementRef.nativeElement, 'atp-filters-panel_' + value.toLowerCase());
    }
    this._prefix = value;
    if (this._prefix) {
      this._renderer.addClass(this._elementRef.nativeElement, 'atp-filters-panel_' + value.toLowerCase());
    }
  }

  @Input() name: string;
  @Input() items: AtpFiltersPanelItem<any>[] = [];
  @Input() isNotExpandable: boolean;
  @Input() filteringButtonLabel: string;
  @Input() clearButtonLabel: string;
  queryParams: any;
  controls: AtpFiltersPanelItem<any>[] = [];
  search: AtpFiltersPanelItem<any>;

  isOpenPanel: boolean = false;

  chips: IAtpFiltersPanelChip[] = [];

  form: FormGroup;

  @Output('filtrationClick') filtrationEvent = new EventEmitter<EventArgs<AtpFiltersPanelComponent, any>>();
  @Output('resetClick') resetEvent = new EventEmitter<EventArgs<AtpFiltersPanelComponent, any>>();

  isPresetsEditMode: boolean = false;
  isPresetsAddMode: boolean = false;

  autocompletes: { [key: string]: IAtpSelectItem[] } = {};

  presetsModeCancel() {
    this.isPresetsAddMode = false;
    this.isPresetsEditMode = false;
  }

  presetsModeConfirm() {
    this.isPresetsAddMode = this.isPresetsAddMode ? null : false;
    this.isPresetsEditMode = this.isPresetsEditMode ? null : false;
  }

  private _toggleChanged: boolean = false;
  get toggleChanged(): boolean {
    return this._toggleChanged;
  }

  set toggleChanged(value: boolean) {
    if (!this.isNotExpandable) {
      setTimeout(() => {
        this._toggleChanged = value;
      });
    }
  }

  ngOnInit() {
    if (this.queryParams) {
      this.fillValues(this.queryParams);

      if (Object.keys(this.queryParams).length) {
        setTimeout(() => {
          this.toggleChanged = true;
        }, 0);
      }
    } else {
      this._activatedRoute.queryParams.subscribe(
        (data) => {
          this.queryParams = data;
          this.fillValues(data);

          if (Object.getOwnPropertyNames(data).length > 0) {
            setTimeout(() => {
              this.toggleChanged = true;
            }, 0);
          }
        }
      );
    }

    this.initAutocomplete();
  }

  public chipRemoveClick(chip: IAtpFiltersPanelChip) {
    const params = {};

    const searchItem = this.items.find(x => (<any>x).isSearch);
    for (const key in this.queryParams) {

      if (chip.name) {
        if (key != chip.name) {
          params[key] = this.queryParams[key];
        }
      } else {
        if (this.chips.find(x => x.name == key) || key == searchItem?.name) {
          params[key] = this.queryParams[key];
        }
      }
    }

    this.fillValues(params, true);
    this.filtrationClick();
  }

  private setChips(params: any) {

    this.chips = [];
    let count = 0;
    for (const key in params) {
      const item = this.items.find(x => x.name == key.split('_')[0]);

      if ((<any>item).isSearch) {
        continue;
      }
      if (this.chips.length == 2) {
        count++;
      }
      if (this.chips.length < 2) {
        let display = item.display + ': ';
        if (item.type == 'Boolean' || item.type == 'Select') {
          display += item.value.value;
        } else if (item.type == 'DateTime') {
          display = item.display + ' ' + (key.split('_')[1] == 'From' ? 'от' : 'до') + ': ' + this._dateTimeService.dateToFormatString(item.value[(key.split('_')[1] == 'From' ? 0 : 1)], 'DateTime');
        } else if (item.type == 'MultipleSelect') {
          const values = [];
          for (const itemValue of item.value) {
            values.push(itemValue.value);
          }
          display += values.join(", ");
        }
        else if (item.type === 'NumberRange'){
          display = item.display + ' ' + (key.split('_')[1] === 'From' ? 'от' : 'до') + ': ' + item.value[(key.split('_')[1] === 'From' ? 0 : 1)];
        }
        else {
          display += item.value;
        }

        this.chips.push({name: key, display});
      }
    }

    if (count > 0) {
      this.chips.push({name: '', display: 'Еще ' + count});
    }
  }

  private _searchSubscription = Subscription.EMPTY;

  public fillValues(values: any, fromPreset: boolean = false) {
    this._searchSubscription.unsubscribe();
    let form: FormGroup;
    if (!this.form) {
      form = new FormGroup({});
    }
    for (const item of this.items) {
      const formControl = this.form ? this.form.controls[item.name] : new FormControl();
      if (!this.form) {
        formControl.valueChanges.subscribe(
          (value) => {
            item.value = value;
          }
        );
        form.addControl(item.name, formControl);
      }
      switch (item.type) {
        case 'Boolean': {

          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset) {
              value = item.value;
            }
          } else {
            value = (<AtpFiltersPanelSelect>item).options.find(x => (values[item.name]?.id ? values[item.name].id : values[item.name]) == x.id);
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }
          formControl.setValue(value);
          break;

          // let value = null;
          //
          //
          // if (values[item.name] === undefined) {
          //   if (!fromPreset && item.value !== undefined && item.value !== null) {
          //     value = item.name + '_' + (!!item.value ? 'True' : 'False');
          //   }
          // } else {
          //   value = values[item.name];
          // }
          // // TODO: вынести
          // if (!fromPreset) {
          //   item.isVisible = !!value || item.isVisibleDefault;
          // }
          // formControl.setValue(value);
          // break;
        }
        case 'Number': {
          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset && (item.value || item.value === 0)) {
              value = +item.value;
            }
          } else {
            if (values[item.name] || values[item.name] === 0) {
              value = +values[item.name];
            }
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }
          formControl.setValue(value);
          break;
        }
        case 'Select': {
          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset) {
              value = item.value;
            }
          } else {
            value = (<AtpFiltersPanelSelect>item).options.find(x => (values[item.name]?.id ? values[item.name].id : values[item.name]) == x.id);
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }
          formControl.setValue(value);
          break;
        }
        case 'MultipleSelect': {
          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset) {
              value = item.value;
            }
          } else {

            let ids = [];

            if (!fromPreset) {
              ids = values[item.name].split(',').map(val => parseInt(val));
            }
            else{
              ids = Array.isArray(values[item.name])? values[item.name].map( val => val.id) : null;
            }

            if (ids) {
              value = (<AtpFiltersPanelSelect>item).options.filter(x => ids.includes(x.id));
            }
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }

          formControl.setValue(value);
          break;
        }
        case 'DateTime': {
          let value: Date[] = [];

          if (!fromPreset) {
            value[0] = values[item.name + '_From'] ? new Date(values[item.name + '_From']) : null;
            value[1] = values[item.name + '_To'] ? new Date(values[item.name + '_To']) : null;
          }

          if (!fromPreset) {
            item.isVisible = !!value[0] || !!value[1] || item.isVisibleDefault;
          }

          if (fromPreset) {
            value[0] = (values[item.name] && values[item.name][0]) ? new Date(values[item.name][0]) : null;
            value[1] = (values[item.name] && values[item.name][1]) ? new Date(values[item.name][1]) : null;
          }

          formControl.setValue(value.length ? value : item.value);
          break;
        }
        case 'NumberRange': {

          let value: number[] = [];

          if (!fromPreset) {
            value[0] = values[item.name + '_From'] ? Number.parseFloat(values[item.name + '_From']) : null;
            value[1] = values[item.name + '_To'] ? Number.parseFloat(values[item.name + '_To']) : null;
          }

          if (!fromPreset) {
            item.isVisible = !!value[0] || !!value[1] || item.isVisibleDefault;
          }

          if (fromPreset) {
            value[0] = values[item.name + '_From'] ? Number.parseFloat(values[item.name + '_From']) : null;
            value[1] = values[item.name + '_To'] ? Number.parseFloat(values[item.name + '_To']) : null;
          }

          formControl.setValue(value.length ? value : item.value);
          break;
        }
        case 'Autocomplete':
          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset) {
              value = item.value;
            }
          } else {
            value = values[item.name];//.replace(/.\[.+\]/, '');
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }
          formControl.setValue(value);
          break;
        default: {
          let value = null;
          if (values[item.name] === undefined) {
            if (!fromPreset) {
              value = item.value;
            }
          } else {
            value = values[item.name];
          }
          if (!fromPreset) {
            item.isVisible = !!value || item.isVisibleDefault;
          }
          formControl.setValue(value);
          break;
        }
      }
    }
    if (!this.form) {
      this.form = form;
    }

    this.search = this.items.find(x => (<any>x).isSearch);
    this.controls = this.items.filter(x => !(<any>x).isSearch && x.isVisible);

    if (!this.search) {
      throw 'Фильтры. Не объявлено поле для поиска';
    }

    let timeout: NodeJS.Timeout;
    this._searchSubscription = this.form.controls[this.search.name].valueChanges.subscribe((val) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        timeout = null;
        this.filtrationClick(true);
      }, 300);
    });
    if (!fromPreset) {
      this.setChips(values);
    }
  }

  filtrationClick(fromSearch: boolean = false) {
    if (!fromSearch) {
      this.isOpenPanel = false;
    }
    let result = {};
    for (const item of this.items) {
      if (item.value != item.defaultValue && (item.type == 'Boolean' || item.value)) {
        switch (item.type) {
          case 'Select':
          case 'Boolean':
            result[item.name] = item.value.id;
            break;
          case 'MultipleSelect':
            const ids = [];
            for (const itemElement of item.value) {
              ids.push(itemElement.id);
            }
            if (ids.length > 0) {
              result[item.name] = ids.join(",");
            }
            break;
          case 'DateTime':
            if (item.value[0]) {
              result[item.name + '_From'] = item.value[0] ? (<Date>item.value[0]).toUTCString() : null;
            }
            if (item.value[1]) {
              result[item.name + '_To'] = item.value[1] ? (<Date>item.value[1]).toUTCString() : null;
            }
            break;
          case 'Autocomplete':
            result[item.name] = item.value;
            break;
          case 'NumberRange':
            if (item.value[0] >= 0) {
              result[item.name + '_From'] = item.value[0];
            }
            if (item.value[1] >= 0) {
              result[item.name + '_To'] = item.value[1];
            }
            break;
          default:
            result[item.name] = item.value;
            break;
        }
      }
    }

    this.filtrationEvent.emit(new EventArgs(this, result));

    if (this.filtersPopupOverlayRef) {
      this.filtersPopupOverlayRef.detach();
    }
  }

  resetClick() {
    this.isOpenPanel = false;

    for (const item of this.items) {
      if (item.value != item.defaultValue) {
        item.value = item.defaultValue;
      }
    }

    this.fillValues({});

    this.resetEvent.emit(new EventArgs(this, null));
    this.filtersPopupOverlayRef.detach();
  }

  filtersPopupOverlayRef: OverlayRef;
  @ViewChild('filtersPopupTemplate') filtersPopupTemplate: TemplateRef<unknown>;

  filtersPopupOpen(htmlElement: HTMLElement) {
    this.filtersPopupOverlayRef = this._overlay.create({
      height: '100%',
      width: '100%',
      positionStrategy:
        this._overlay.position()
          .flexibleConnectedTo(htmlElement)
          .withPositions([
            {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
          ])
          .withPush(false),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });

    const portal = new TemplatePortal(this.filtersPopupTemplate, this._viewContainerRef);
    this.filtersPopupOverlayRef.attach(portal);
  }

  addFieldPopupOverlayRef: OverlayRef;
  @ViewChild('addFieldPopupTemplate') addFieldPopupTemplate: TemplateRef<unknown>;

  addFields(htmlElement: HTMLElement) {
    this.addFieldPopupOverlayRef = this._overlay.create({
      positionStrategy:
        this._overlay.position()
          .flexibleConnectedTo(htmlElement)
          .withPositions([
            {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
            {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
            {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
            {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'}
          ])
          .withPush(false),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });

    const portal = new TemplatePortal(this.addFieldPopupTemplate, this._viewContainerRef);
    this.addFieldPopupOverlayRef.attach(portal);
  }

  get addFieldsColumns(): string {
    const count = this.items.length / 8;
    let result = 'grid-template-columns:';
    for (let i = 0; i < count; i++) {
      result += ' 1fr';
    }
    return result + ';';
  }

  addFieldCheckedChange(item: AtpFiltersPanelItem<any>, checked: boolean) {
    item.isVisible = checked;

    this.controls = this.items.filter(x => !(<any>x).isSearch && x.isVisible);
    setTimeout(() => {
      this.addFieldPopupOverlayRef.updatePosition();
    });
  }

  ngOnDestroy() {
    if (this.addFieldPopupOverlayRef) {
      this.addFieldPopupOverlayRef.detach();
    }
    if (this.filtersPopupOverlayRef) {
      this.filtersPopupOverlayRef.detach();
    }
  }

  initAutocomplete(): void {
    for (const item of this.items) {
      if (item.type !== 'Autocomplete'){
        continue;
      }
      if (this.form.controls[item.name]){
        this.form.controls[item.name].valueChanges.pipe(debounceTime(500)).subscribe(
          (value: any) => {
            if (!value) {
              this.autocompletes[item.name] = [];
            }
            else if (typeof (value) === 'string') {
              this.autocomplete(item.name, value);
            }
          }
        );
      }
    }
  }

  protected autocomplete(name: string, value: string, isMultiple = false) {

    let excludedIds: any[] = null;
    if (isMultiple && this.form.controls[name].value) {
      excludedIds = this.form.controls[name].value.map(x => x.id);
    }
    const subscription = (<Observable<IAtpSelectItem[]>>this.api[`autocomplete${name.substr(0, 1).toUpperCase()}${name.substr(1)}`](value, excludedIds)).subscribe(
      (data: IAtpSelectItem[]) => {
        for (const item of data) {
          (<any>item).toString = (): string => { return item.value + '[' + item.id + ']' };
        };
        this.autocompletes[name] = data;
        subscription.unsubscribe();
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.autocomplete(name, value, isMultiple); }, null, [new AtpHttpErrorHandler(400, err.error)]);
        subscription.unsubscribe();
      }
    );

    // this.subscriptions.add(subscription);
  }
}
